import React from 'react';

import {
  Form,
  TextInput,
  SubmitButton,
} from 'custom-form-client/react';

import Seo from '../../components/Seo';
import AppHeader from '../../components/AppHeader';
import BaseLayout from '../../layouts/BaseLayout';
import Section from '../../components/Section';

import styles from './ChromeExtension.module.scss';
import Steps from './components/Steps';

import {
  CAPTCHA_URL_BASE,
  GRABOXY_API_KEY,
  TRAP_SERVER_URL,
} from '../../../constants';

const ChromeExtension = () => (
  <BaseLayout>
    <Seo />
    <AppHeader />
    <main>
      <Section
        id="chrome-extension-subscription"
        title="Graboxy Chrome Extension"
        className={styles.ChromeExtension}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 mb-3 text-center">
              <h3>
                Defend your browser with continuous biometric authentication
              </h3>
              <h4>
                Sign up now to get instant access when our extension is alive
              </h4>
            </div>

            <div
              className="col-12 mx-auto text-center"
              style={{ maxWidth: 576 }}
            >
              <Form
                id="chromeExtensionForm"
                className={styles.ChromeExtensionForm}
                type="chromeExtension"
                action={process.env.REACT_APP_CUSTOM_FORM_URL}
                responseMessage="Thank you! We will notify you when the extension is available."
                captchaType="graboxyCaptcha"
                captchaSiteKey={process.env.REACT_APP_GRABOXY_CAPTCHA_SITE_KEY}
                captchaOptions={{
                  urlBase: CAPTCHA_URL_BASE,
                  apiKey: GRABOXY_API_KEY,
                  trapUrl: TRAP_SERVER_URL,
                }}
              >
                <TextInput
                  id="extension-email"
                  name="email"
                  type="email"
                  placeholder="Email"
                  required
                />
                <SubmitButton className="btn">
                  Sign up
                </SubmitButton>
              </Form>
            </div>
          </div>
        </div>
      </Section>
      <Section
        id="chrome-extension-how-it-works"
        title="How Graboxy Chrome Extension Works"
        className={styles.HowItWorks}
      >
        <div className="container">
          <Steps />
        </div>
      </Section>
    </main>
  </BaseLayout>
);

export default ChromeExtension;
